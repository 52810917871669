import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Button, Typography, Card, CardActionArea, CardMedia, CardContent, CardActions } from '@material-ui/core';
import { useHover } from '../../util/hooks/use-hover';
import { BLACK, LOGO_SECONDARY } from '../../util/theme';
import StringTable from '../../i18n/en-us/strings.json'; 

//******************************************************************************
const STRINGS = StringTable.index.OurProductsBrief;
//******************************************************************************

const useStyles = makeStyles((theme) => ({
  container: {
    cursor: 'pointer',
  },
  title: {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    padding: 10,
    color: LOGO_SECONDARY.css(),
    filter: `drop-shadow(0 0 5px ${BLACK.css()})`,
    transition: 'opacity 250ms',
  },
  hidden: {
    opacity: 0,
  },
  img: {
    height: (props) => props.expanded ? 300 : 200,
    transition: 'height 400ms'
  },
}));
//------------------------------------------------------------------------------
export const IndustryItem = (props) =>
{
  const {expanded, title, image, description, onClick} = props;
  const [hoverRef, isHovered] = useHover();
  const classes = useStyles(props);
  return (
    <Card ref={hoverRef} className={classes.container}>
      <CardActionArea>
        <CardMedia className={classes.img} image={image} title={title}>
          <Typography className={clsx(classes.title, !expanded && !isHovered && classes.hidden)} variant={'h5'} component={'h2'}>
            {title}
          </Typography>
        </CardMedia>
        {
          expanded ?
          <CardContent>
            <Typography variant={'body2'} component={'p'}>
              {description}
            </Typography>
          </CardContent> :
          null
        }
      </CardActionArea>
      {
        expanded ?
        <CardActions>
          <Button size={'small'} color={'primary'} onClick={onClick}>{STRINGS.button}</Button>
        </CardActions> :
        null
      }
    </Card>
  );
}
//******************************************************************************