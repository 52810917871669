import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { LOGO_SECONDARY } from '../../util/theme';
// import img from '../../assets/images/do_more.jpg';
import StringTable from '../../i18n/en-us/strings.json'; 
import { Video } from '../video';

//******************************************************************************
const useStyles = makeStyles(theme =>
({
  container: {
    display: 'flex',
    flex: '1 0 auto',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    backgroundColor: '#DCDCDC',
    padding: 30,
  },
  description:{
    flex:'0 0 auto',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-start',
    position: 'relative',
    width: '40%',
    zIndex: 10,
    padding: [[10, 100, 10, 10]],
  },
  descriptionTitle: {
    flex: '0 0 auto',
    color: LOGO_SECONDARY.css(),
    fontSize: 50,
    cursor: 'default',
  },
  descriptionText: {
    flex: '1 0 auto',
    fontSize: 22,
    cursor: 'default',
  },
  img: {
    flex: '0 0 auto',
    position: 'relative',
    objectFit: 'cover',
    width: '50%',
    zIndex: 1,
  },
}));
//------------------------------------------------------------------------------
const STRINGS = StringTable.index.Slogan;
//------------------------------------------------------------------------------
export function Slogan(props)
{
  const classes = useStyles(props);
  return(
    <div className={clsx(props.className, classes.container)}>
      <div className={classes.description}>
        <div className={classes.descriptionTitle}>{STRINGS.titleOptimize}</div>
        <div className={classes.descriptionTitle}>{STRINGS.titleYour}</div>
        <div className={classes.descriptionTitle}>{STRINGS.titleBusiness}.</div>
        <div className={classes.descriptionTitle}>{STRINGS.titleEffortlessly}.</div>
        <p className={classes.descriptionText}>
          {STRINGS.contentL1}
          <br/>
          {STRINGS.contentL2}
          <br/>
          {STRINGS.contentL3},
          <br/>
          {STRINGS.contentL4}.
        </p>
      </div>
      {/* <img className={classes.img} src={img} alt={''}/> */}
      <Video
        className={classes.img}
        videoSrcURL={'https://www.youtube-nocookie.com/embed/H6j8xPDwflg?modestbranding=1'} videoTitle={'Velacto introduction'}
      />
    </div>
  );
}
//******************************************************************************