import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { navigate } from 'gatsby';

import imgYoga from '../../assets/images/yoga.jpg';
import imgFitness from '../../assets/images/fitness.jpg';
import imgSchools from '../../assets/images/school.jpg';
import imgDance from '../../assets/images/dance_2.jpg';
import imgMuseums from '../../assets/images/Museum_1.jpg';
import imgArt from '../../assets/images/art.jpg';
import imgConference from '../../assets/images/conference.jpg';
import imgSpa from '../../assets/images/spa.jpg';

import { AnimatedGrid } from '../animated-grid/grid';
import { AnimatedGridItem } from '../animated-grid/item';
import { IndustryItem } from './insustry-item';
import { LOGO_SECONDARY } from '../../util/theme';
import StringTable from '../../i18n/en-us/strings.json'; 

//******************************************************************************
const STRINGS = StringTable.index.Industries;

//******************************************************************************
const useStyles = makeStyles(theme =>
({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: '1 0 auto',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
    padding: [[10, 10, 30, 10]],
    alignItems: 'center'
  },
  containerInner: {
    flex: '1 0 auto',
    backgroundColor: '#DCDCDC',
    padding: 20,
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit:'cover',
  },
  title:{
    flex:'0 0 auto',
    color: LOGO_SECONDARY.css(),
    fontSize: 40,
    alignItems: 'center',
  },
}));

//------------------------------------------------------------------------------
export function Industries(props)
{
  const classes = useStyles(props);
  return(
    <div id={props.id || null} className={classes.container}>
      <div className={classes.title}>{STRINGS.title}</div>
      <AnimatedGrid className={clsx(props.className, classes.containerInner)}
          animationOptions={{ easing : 'backOut', stagger: 10, duration: 400 }}>
          <AnimatedGridItem grid={{expanded: {row: 'span 2', col: 'span 2'}}}>
            <IndustryItem title={STRINGS.titleYS}
              image={imgYoga}
              description={STRINGS.descriptionYS}
              onClick={() => navigate('/industries/#yoga')}/>
          </AnimatedGridItem>
          <AnimatedGridItem grid={{expanded: {row: 'span 2', col: 'span 2'}}}>
            <IndustryItem title={STRINGS.titleFG}
              image={imgFitness}
              description={STRINGS.descriptionFG}
              onClick={() => navigate('/industries/#fitness')}/>
          </AnimatedGridItem>
          <AnimatedGridItem grid={{expanded: {row: 'span 2', col: 'span 2'}}}>
            <IndustryItem title={STRINGS.titleACS}
              image={imgArt }
              description={STRINGS.descriptionACS}
              onClick={() => navigate('/industries/#art')}/>
          </AnimatedGridItem>
          <AnimatedGridItem grid={{expanded: {row: 'span 2', col: 'span 2'}}}>
            <IndustryItem title={STRINGS.titleDS}
              image={imgDance}
              description={STRINGS.descriptionDS}
              onClick={() => navigate('/industries/#dance')}/>
          </AnimatedGridItem>
          <AnimatedGridItem grid={{expanded: {row: 'span 2', col: 'span 2'}}}>
            <IndustryItem title={STRINGS.titleCE}
              image={imgConference}
              description={STRINGS.descriptionCE}
              onClick={() => navigate('/industries/#events')}/>
          </AnimatedGridItem>
          <AnimatedGridItem grid={{expanded: {row: 'span 2', col: 'span 2'}}}>
            <IndustryItem title={STRINGS.titleM}
              image={imgMuseums}
              description={STRINGS.descriptionM}
              onClick={() => navigate('/industries/#museum')}/>
          </AnimatedGridItem>
          <AnimatedGridItem grid={{expanded: {row: 'span 2', col: 'span 2'}}}>
            <IndustryItem title={STRINGS.titleSBS}
              image={imgSpa}
              description={STRINGS.descriptionSBS}
              onClick={() => navigate('/industries/#salon')}/>
          </AnimatedGridItem>
          <AnimatedGridItem grid={{expanded: {row: 'span 2', col: 'span 2'}}}>
            <IndustryItem title={STRINGS.titleS}
              image={imgSchools}
              description={STRINGS.descriptionS}
              onClick={() => navigate('/industries/#school')}/>
          </AnimatedGridItem>
        </AnimatedGrid>
    </div>
  );
}
//******************************************************************************