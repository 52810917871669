import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { navigate } from 'gatsby';

import imgRM from '../../assets/images/dashboard_2_1.jpg';
import imgScheduler1 from '../../assets/images/schedule_1.jpg';
import imgHandshake from '../../assets/images/handshake.jpg';
import imgDocumentSign from '../../assets/images/document_sign.jpg';

import { AnimatedGrid } from '../animated-grid/grid';
import { AnimatedGridItem } from '../animated-grid/item';
import { IndustryItem } from './insustry-item';
import { LOGO_SECONDARY } from '../../util/theme';
import StringTable from '../../i18n/en-us/strings.json'; 

//******************************************************************************
const STRINGS = StringTable.index.OurProductsBrief;

//******************************************************************************
const useStyles = makeStyles(theme =>
({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: '1 0 auto',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
    padding: [[10, 10, 30, 10]],
    alignItems: 'center'
  },
  containerInner: {
    flex: '1 0 auto',
    backgroundColor: '#DCDCDC',
    padding: 20,
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit:'cover',
  },
  title:{
    flex:'0 0 auto',
    color: LOGO_SECONDARY.css(),
    fontSize: 40,
    alignItems: 'center',
  },
}));

//------------------------------------------------------------------------------
export function OurProductsBrief(props)
{
  const classes = useStyles(props);
  return(
    <div className={classes.container}>
      <div className={classes.title}>{STRINGS.title}</div>
      <AnimatedGrid className={clsx(props.className, classes.containerInner)}
          animationOptions={{ easing : 'backOut', stagger: 10, duration: 400 }}>
          <AnimatedGridItem grid={{expanded: {row: 'span 2', col: 'span 2'}}}>
            <IndustryItem title={STRINGS.titleRMS}
              image={imgRM}
              description={STRINGS.descriptionRMS}
              onClick={() => navigate('/products/#rms')}/>
          </AnimatedGridItem>
          <AnimatedGridItem grid={{expanded: {row: 'span 2', col: 'span 2'}}}>
            <IndustryItem title={STRINGS.titleRS}
              image={imgScheduler1}
              description={STRINGS.descriptionRS}
              onClick={() => navigate('/products/#scheduler')}/>
          </AnimatedGridItem>
          <AnimatedGridItem grid={{expanded: {row: 'span 2', col: 'span 2'}}}>
            <IndustryItem title={STRINGS.titleCE}
              image={imgHandshake}
              description={STRINGS.descriptionCE}
              onClick={() => navigate('/products/#customers')}/>
          </AnimatedGridItem>
          <AnimatedGridItem grid={{expanded: {row: 'span 2', col: 'span 2'}}}>
            <IndustryItem title={STRINGS.titleRM}
              image={imgDocumentSign}
              description={STRINGS.descriptionRM}
              onClick={() => navigate('/products/#dms')}/>
          </AnimatedGridItem>
        </AnimatedGrid>
    </div>
  );
}
//******************************************************************************