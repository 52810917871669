import React, { useState, isValidElement, cloneElement } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

//******************************************************************************
const gridVal = (grid, path, def = 'span 1') =>
{
  if(grid === undefined || grid === null)
    return def;
  for(const key of path)
  {
    grid = grid[key];
    if(grid === undefined || grid === null)
      return def;
  }
  return grid;
}
//------------------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  default: {
    gridRow: ({grid}) => gridVal(grid, ['default', 'row']),
    gridColumn: ({grid}) => gridVal(grid, ['default','col']),
  },
  expanded: {
    gridRow: ({grid}) => gridVal(grid, ['expanded', 'row'], gridVal(grid, ['default', 'row'])),
    gridColumn: ({grid}) => gridVal(grid, ['expanded','col'], gridVal(grid, ['default','col'])),
  },
}));
//------------------------------------------------------------------------------
export const AnimatedGridItem = (props) =>
{
  const classes = useStyles(props);
  const [expanded, setExpanded] = useState(props.expanded);
  if(typeof props.expanded === 'boolean' && expanded !== props.expanded)
    setExpanded(props.expanded);
  const children = React.Children.map(props.children, (child) =>
  {
    if(isValidElement(child))
      return cloneElement(child, {expanded});
    return child;
  });
  return (
    <div className={clsx(props.className, expanded ? classes.expanded : classes.default)}
      onClick={() => setExpanded(!expanded)}>
      <div>
        {children}
      </div>
    </div>
  );
}
//******************************************************************************