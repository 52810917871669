import React from 'react';
import { navigate } from 'gatsby';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { WHITE, LOGO_SECONDARY, LOGO_PRIMARY_LIGHT } from '../../util/theme';
import img from '../../assets/images/whyVelacto.jpg';
import { Button } from '@material-ui/core';
import StringTable from '../../i18n/en-us/strings.json'; 

//******************************************************************************
const STRINGS = StringTable.index.WhyVelacto;

const useStyles = makeStyles(theme =>
({
  container: {
    display: 'flex',
    flex: '1 0 auto',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    backgroundColor: LOGO_PRIMARY_LIGHT.css(),
    height: 400,
    padding: 50,
  },
  description:{
    flex:'0 0 auto',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-start',
    clipPath: 'polygon(0 0, calc(100% - 100px) 0, 100% 100%, 0 100%)',
    position: 'relative',
    top: -10,
    left: 100,
    backgroundColor: WHITE.css(),
    width: '40%',
    zIndex: 10,
    padding: [[10, 100, 10, 20]],
  },
  descriptionTitle: {
    flex: '0 0 auto',
    color: LOGO_SECONDARY.css(),
    fontSize: 24,
    cursor: 'default',
  },
  descriptionText: {
    flex: '1 0 auto',
    fontSize: 14,
    cursor: 'default',
  },
  img: {
    flex: '0 0 auto',
    clipPath: 'polygon(0 0, 100% 0, 100% 100%, 100px 100%)',
    position: 'relative',
    top: 10,
    right: 45,
    objectFit: 'cover',
    width: '50%',
    zIndex: 1,
  },
}));
//------------------------------------------------------------------------------
export function WhyVelacto(props)
{
  const classes = useStyles(props);
  return(
    <div className={clsx(props.className, classes.container)}>
      <div className={classes.description}>
        <div className={classes.descriptionTitle}>{STRINGS.titleWhy}</div>
        <div className={classes.descriptionTitle}>{STRINGS.titleVelacto}</div>
        <p className={classes.descriptionText}>
          {STRINGS.contentL1}
          <br/>
          {STRINGS.contentL2}
          <br/>
          {STRINGS.contentL3}
          <br/>
          <br/>
          {STRINGS.contentL4}
          <br/>
        </p>
        <Button variant={'contained'} onClick={() => navigate('/industries/')}>
          {STRINGS.buttonName}
        </Button>
      </div>
      <img className={classes.img} src={img} alt={''}/>
    </div>
  );
}
//******************************************************************************