import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { WhyVelacto } from '../components/index/why-velacto';
import { WhoWeAre } from '../components/index/who-we-are';
import { GetInTouch } from '../components/index/get-in-touch';
import { Industries } from '../components/index/industries';
import { Slogan } from '../components/index/slogan';
import { OurProductsBrief } from '../components/index/our-products-brief';

//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  item: {
    minHeight: '50vh',
    border: 'solid 1px blue',
  },
}));
//------------------------------------------------------------------------------
export function HomePage(props)
{
  const classes = useStyles(props);
  return (
    <div className={classes.container}>
      <Slogan/>
      <OurProductsBrief/>
      <WhyVelacto id={'benefits'}/>
      <Industries id={'industries'}/>
      <WhoWeAre id={'about'}/>
      <GetInTouch id={'contactus'}/>
    </div>
  );
}
//------------------------------------------------------------------------------
export default HomePage;
//******************************************************************************