import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const isSSR = typeof window === 'undefined';
let wrapGrid = null
if (!isSSR) {
  wrapGrid = require('animate-css-grid').wrapGrid
}
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateRows: 'minmax(200px, 1fr)',
    gridTemplateColumns: 'repeat(4, minmax(50px, 1fr))',
    gridAutoRows: '1fr',
    gridGap: 20,
    gridAutoFlow: 'dense',
  },
}));
//------------------------------------------------------------------------------
export const AnimatedGrid = (props) =>
{
  const gridRef = useRef();
  const classes = useStyles(props);
  const {animationOptions} = props;
  useEffect(() =>
  {
    if(gridRef)
    {
      const { unwrapGrid } = wrapGrid(gridRef.current, animationOptions);
      return unwrapGrid;
    }
  }, [animationOptions]);
  return (
    <div ref={gridRef} className={clsx(props.className, classes.container)}>
      {props.children}
    </div>
  );
}
//******************************************************************************