import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { WHITE, LOGO_SECONDARY } from '../../util/theme';
import img from '../../assets/images/contact_us.jpg';
import { Button, TextField, CircularProgress } from '@material-ui/core';
import { useAsync } from '../../util/hooks/use-async';
import StringTable from '../../i18n/en-us/strings.json'; 
import { sendContactInfo } from '../../util/send-contact-info';

//******************************************************************************
const STRINGS = StringTable.index.GetInTouch;

//******************************************************************************
const useStyles = makeStyles(theme =>
({
  container: {
    display: 'flex',
    flex: '1 0 auto',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    backgroundColor: LOGO_SECONDARY.css(),
    height: 650,
    padding: 50,
  },
  description:{
    flex:'0 0 auto',
    display: 'flex',
    flexFlow: 'column nowrap',
    position: 'relative',
    top: 10,
    right: 45,
    backgroundColor: WHITE.css(),
    width: '40%',
    zIndex: 10,
    padding: [[10, 20, 10, 50]],
  },
  descriptionTitle: {
    flex: '0 0 auto',
    color: LOGO_SECONDARY.css(),
    fontSize: 24,
    cursor: 'default',
  },
  descriptionText: {
    flex: '0 0 auto',
    fontSize: 14,
    cursor: 'default',
  },
  form: {
    display: 'flex',
    flex: '1 0 auto',
    flexFlow: 'column nowrap',
  },
  formFld: {
    flex: '0 0 auto',
    marginBottom: 5,
  },
  formMsg: {
    extend: 'formFld',
    flex: '1 0 auto',
  },
  formBtn: {
    alignSelf: 'flex-start',
  },
  img: {
    flex: '0 0 auto',
    clipPath: 'polygon(0 0, calc(100% - 100px) 0, 100% 100%, 0 100%)',
    position: 'relative',
    top: -10,
    left: 100,
    objectFit: 'cover',
    width: '50%',
    zIndex: 1,
  },
  btnProgress: {
    padding: 2
  },
  btnErroGroup: {
    display: 'flex',
    alignSelf: 'flex-start',
    flex: '0 0 auto',
    flexFlow: 'row nowrap',
  },
  btnError: {
    marginRight: 10
  }
}));
//------------------------------------------------------------------------------
export function GetInTouch(props)
{
  const classes = useStyles(props);
  const [company, setCompany] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const send = useAsync(async () => sendContactInfo({greeting: company, label: name, email, message}), false);
  const msgSent = send.value !== null || send.error !== null;
  const reset = () => {
    send.reset();
    setMessage('');
  };
  return(
    <div className={clsx(props.className, classes.container)}>
      <img className={classes.img} src={img} alt={''}/>
      <div className={classes.description}>
        <div className={classes.descriptionTitle}>{STRINGS.title}</div>
        <p className={classes.descriptionText}>
          {STRINGS.contentL}
        </p>
        { !msgSent ?
        <div className={classes.form}>
          <TextField className={classes.formFld} disabled={send.pending} fullWidth required label={'Name'} onChange={(e) => setName(e.target.value)} value={name}/>
          <TextField className={classes.formFld} disabled={send.pending} fullWidth required label={'Email'} onChange={(e) => setEmail(e.target.value)} value={email}/>
          <TextField className={classes.formFld} disabled={send.pending} fullWidth label={'Company'} onChange={(e) => setCompany(e.target.value)} value={company}/>
          <TextField className={classes.formMsg} disabled={send.pending} fullWidth multiline rows={4} rowsMax={12} label={'Message'} onChange={(e) => setMessage(e.target.value)} value={message}/>
          <Button className={classes.formBtn} disabled={send.pending} variant={'contained'} onClick={() => send.execute()}>
            {send.pending ? <CircularProgress className={classes.btnProgress} size={20}/> : null}
            {send.pending ? STRINGS.buttonSend : STRINGS.buttonSubmit}
          </Button>
        </div> :
        send.value != null && send.value.success ?
        <div className={classes.form}>
          <p>
            Your message has been submited.
          </p>
          <Button className={classes.formBtn} variant={'contained'} onClick={reset}>
            Send Another Message
          </Button>
        </div> :
        <div className={classes.form}>
          <p>
            Something went wrong.
          </p>
          <div className={classes.btnErroGroup}>
            <Button className={classes.btnError} variant={'contained'} onClick={() => send.execute()}>
              {STRINGS.buttonReSubmit}
            </Button>
            <Button className={classes.btnError} variant={'contained'} onClick={reset}>
              {STRINGS.buttonCancel}
            </Button>
          </div>
        </div>
        }
      </div>
    </div>
  );
}
//******************************************************************************