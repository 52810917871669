import React from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

//******************************************************************************
const useStyles = makeStyles((theme) => ({
  iframe: {
    width: '100%',
    height: '100%',
  },
}));
//------------------------------------------------------------------------------
export function Video(props)
{
  const classes = useStyles(props);
  return (
    <div className={props.className}>
      <iframe
        className={classes.iframe}
        src={props.videoSrcURL}
        title={props.videoTitle}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </div>
  );
}
//******************************************************************************