import React from 'react';
import { navigate } from 'gatsby';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { WHITE, LOGO_PRIMARY_LIGHT, LOGO_SECONDARY } from '../../util/theme';
import img from '../../assets/images/WhoWeAre.jpg';
import { Button } from '@material-ui/core';
import StringTable from '../../i18n/en-us/strings.json'; 

//******************************************************************************
const STRINGS = StringTable.index.WhoWeAre;

//******************************************************************************
const useStyles = makeStyles(theme =>
({
  container: {
    display: 'flex',
    flex: '1 0 auto',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    backgroundColor: LOGO_PRIMARY_LIGHT.css(),
    height: 500,
    padding: 50,
  },
  description:{
    flex:'0 0 auto',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-end',
    clipPath: 'polygon(0 0, 100% 0, 100% 100%, 100px 100%)',
    position: 'relative',
    top: 10,
    right: 45,
    backgroundColor: WHITE.css(),
    width: '40%',
    zIndex: 10,
    padding: [[10, 20, 10, 50]],
  },
  descriptionTitle: {
    flex: '0 0 auto',
    color: LOGO_SECONDARY.css(),
    fontSize: 24,
    cursor: 'default',
  },
  descriptionText: {
    flex: '1 0 auto',
    fontSize: 14,
    cursor: 'default',
    textAlign: 'right',
  },
  img: {
    flex: '0 0 auto',
    clipPath: 'polygon(0 0, calc(100% - 100px) 0, 100% 100%, 0 100%)',
    position: 'relative',
    top: -10,
    left: 100,
    objectFit: 'cover',
    width: '50%',
    zIndex: 1,
  },
}));
//------------------------------------------------------------------------------
export function WhoWeAre(props)
{
  const classes = useStyles(props);
  return(
    <div className={clsx(props.className, classes.container)}>
      <img className={classes.img} src={img} alt={''}/>
      <div className={classes.description}>
        <div className={classes.descriptionTitle}>{STRINGS.title}</div>
        <p className={classes.descriptionText}>
          {STRINGS.contentL1}
          <br/>
          <br/>
          {STRINGS.contentL2}
          <br/>
          <br/>
          {STRINGS.contentL3}
          <br/>
          <br/>
          {STRINGS.contentL4} 
          <br/>
          {STRINGS.contentL5}
          <br/>
          {STRINGS.contentL6}
        </p>
        <Button variant={'contained'} onClick={() => navigate('/about/')}>
          {STRINGS.buttonName}
        </Button>
      </div>
    </div>
  );
}
//******************************************************************************